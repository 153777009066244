import { ChangeEvent, memo } from 'react'
import { TablePagination } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setRowPageCount } from '../../../store/breadCrumb/breacrumbsSlice'
import { getPageCount } from '../../lib/utils'

interface Props {
  total?: number
  pageNumber: number
  setPageNumber?: (t: number) => void
  handleChange?: (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
    page: number
  ) => void
  totalPages?: number
}

export const UIPagination = memo((props: Props) => {
  const { total, totalPages, pageNumber, setPageNumber, handleChange } = props
  const rowPageCount = useAppSelector((state) => state.breadCrumb.rowPageCount)

  const dispatch = useAppDispatch()

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setRowPageCount(Number(event.target.value)))
    setPageNumber && setPageNumber(0)
    handleChange && handleChange(event, 0)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[20, 50, 100]}
      variant="footer"
      count={totalPages ? getPageCount(totalPages, rowPageCount) : total || 0}
      page={pageNumber}
      onPageChange={(_, newPage: number) => {
        handleChange && handleChange(undefined, newPage)
        setPageNumber && setPageNumber(newPage)
      }}
      rowsPerPage={rowPageCount}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
})
