import { coreApi } from 'shared/api/core/coreApi'
import { ResponseTypeWrapper } from 'shared/api/core/types'
import { AppointmentUsersResponse } from './types'

export const appointmentUsersApi = coreApi.injectEndpoints({
  endpoints(build) {
    return {
      getAppointmentUsers: build.query<
        ResponseTypeWrapper<AppointmentUsersResponse>,
        { materialId: string | number; pageNumber: number; pageSize: number }
      >({
        query: (params) => ({
          url: `appointment/${params.materialId}/by-course-id`,
          method: 'get',
        }),

        providesTags: ['APPOINTMENT_USERS'],
      }),
    }
  },
  overrideExisting: false,
})

export const { useGetAppointmentUsersQuery } = appointmentUsersApi
